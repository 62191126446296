module.exports = [{
      plugin: require('../node_modules/@outdoormap/gatsby-plugin-brand-sites/gatsby-browser.js'),
      options: {"plugins":[],"apiUrl":"https://api.naturkartan.se","apiKey":"d311e87a-2a92-48b6-afa5-5fb3d0403fbe","guideId":"626","siteUrl":"https://de.hittafiske.se","lang":"de","typesCategories":{"planyourtrip":["118","55","56","26","229","105","73","74","57"]},"typesCategoriesIncluded":{"planyourtrip":["118","55","56","26","229","105","73","74","57"]},"includeListsInTours":true,"imagePlaceholderColor":"#e09225","templates":{"type":"/build/fce939cc-801a-4f50-b0dc-e66df787295c/src/templates/type.js","site":"/build/fce939cc-801a-4f50-b0dc-e66df787295c/src/templates/site.js","page":"/build/fce939cc-801a-4f50-b0dc-e66df787295c/src/templates/page.js","articles":"/build/fce939cc-801a-4f50-b0dc-e66df787295c/src/templates/articles.js","articlesPost":"/build/fce939cc-801a-4f50-b0dc-e66df787295c/src/templates/articles-post.js","municipalities":"/build/fce939cc-801a-4f50-b0dc-e66df787295c/src/templates/municipalities.js","municipalitiesPost":"/build/fce939cc-801a-4f50-b0dc-e66df787295c/src/templates/municipalities-post.js","search":"/build/fce939cc-801a-4f50-b0dc-e66df787295c/src/templates/search.js"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"de","name":"Hitta Fiske","short_name":"Hitta Fiske","description":"Vattennära upplevelser som du bär med dig länge!","theme_color":"#224d91","background_color":"#fff","icon":"static/meta-images/icon.png","start_url":"/","display":"standalone","cache_busting_mode":"none","include_favicon":false,"legacy":false,"theme_color_in_head":true,"crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
